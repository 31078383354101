<template>
  <div class="bg-cwe">
    <div class="container-my" v-loading="loadVacancy">
      <div class="logins text-center">
        <img
          class="bekmega"
          :src="
            open_vacancy.branch &&
            open_vacancy.branch.company &&
            open_vacancy.branch.company.logo
              ? backendUrl + open_vacancy.branch.company.logo
              : '/img/aw.png'
          "
          alt=""
        />
        <img class="gohr" src="/img/gohr_new.png" alt="" />
      </div>
      <el-dropdown
        class="flag"
        style="float: right;
        z-index: 500;
        margin-top: -30px;"
        trigger="click"
        @command="setLanguage"
      >
        <el-button class="p-1">
          <span class="el-dropdown-link">
            <!-- <img src="/img/flag/russia.svg" alt class="flag_img" /> -->
            <img
              v-if="this.$i18n.locale == 'uz'"
              src="/img/flag/uzbekistan.svg"
              alt
              class="flag_img"
            />
            <img
              v-if="this.$i18n.locale == 'ru'"
              src="/img/flag/russia.svg"
              alt
              class="flag_img"
            />
            <img
              v-if="this.$i18n.locale == 'en'"
              src="/img/flag/english.svg"
              alt
              class="flag_img"
            />
            <img
              v-if="this.$i18n.locale == 'tr'"
              src="/img/flag/turkey.svg"
              alt
              class="flag_img"
            />
            <!-- <i class="el-icon-arrow-down el-icon--right"></i> -->
          </span>
        </el-button>
        <template #dropdown>
          <el-dropdown-menu slot="dropdown" class="flag_ul">
            <el-dropdown-item class="drop-item" command="tr">
              <img src="/img/flag/turkey.svg" alt class="flag_img flag_img2" />
              <span>Turkey</span>
            </el-dropdown-item>
            <el-dropdown-item class="drop-item" command="uz">
              <img
                src="/img/flag/uzbekistan.svg"
                alt
                class="flag_img flag_img2"
              />
              <span>Uzbek</span>
            </el-dropdown-item>
            <el-dropdown-item class="drop-item" command="ru">
              <img src="/img/flag/russia.svg" alt class="flag_img flag_img2" />
              <span>Russia</span>
            </el-dropdown-item>

            <el-dropdown-item class="drop-item" command="en">
              <img src="/img/flag/english.svg" alt class="flag_img flag_img2" />
              <span>English</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <div class="block_1" v-if="open_vacancy.branch">
        <a href="#aaaaaa" class="link-mobil">
          <i class="el-icon-edit-outline"></i>
          {{ $t("message.complete_form") }}
          <i class="el-icon-arrow-down"></i>
        </a>
        <h2 class="mb-0">
          Добро пожаловать! <br />
          Открытие вакансии компании
          <span>
            {{
              open_vacancy.branch.company
                ? open_vacancy.branch.company.name
                : ""
            }}!
          </span>
        </h2>

        <el-row :gutter="20">
          <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
            <p class="font-medium pb-1 pt-0 mb-3 mt-3 text-left">
              <strong class="mr-1 font-bold mr-2">
                {{ $t("message.branch") }}:
              </strong>
              {{ open_vacancy.branch.name }}
            </p>
          </el-col>
        </el-row>
      </div>

      <div
        id="aaaaaa"
        class="block_2"
        v-if="open_vacancy.vacancies && open_vacancy.vacancies.length > 0"
      >
        <h2>{{ $t("message.vacancy") }}</h2>
        <ul class="vacancy_list">
          <li
            v-for="(vacancy, index) in open_vacancy.vacancies"
            :key="vacancy.uuid"
            class="vacancy_list_item"
          >
            <a :href="'/applicationCandidates/' + vacancy.uuid">
              {{ index + 1 }}. {{ vacancy.name }}
            </a>
          </li>
        </ul>
      </div>
      <div class="block_2" v-else>
        {{ $t("message.vacancyNotAvailable") }}
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import { i18n } from "@/utils/i18n";
export default {
  name: "applicationCandidates",
  components: {
    //
  },
  data() {
    return {
      test: "",
      system_language: localStorage.getItem("sys_language")
        ? localStorage.getItem("sys_language")
        : "ru",
      vacancyUuid: this.$route.params.vacancyUuid,
      baseUrl: process.env.VUE_APP_URL,
      backendUrl: process.env.VUE_APP_URL.substring(
        0,
        process.env.VUE_APP_URL.length - 4
      ),
      vacancy_ids: [],
      open_vacancy: {},
      loadVacancy: false
    };
  },
  created() {
    this.fetchVacancyForCandidate();
  },
  computed: {
    ...mapGetters({
      candidateColumns: "candidate/columns",
      columns: "vacancy/columns",
      rules: "candidate/rules"
    })
  },

  methods: {
    ...mapActions({
      save: "candidate/storeAplicationCandidates",
      empty: "candidate/empty"
    }),
    fetchVacancyForCandidate() {
      if (!this.loadVacancy) {
        this.loadVacancy = true;
        axios
          .get(this.baseUrl + "/vacancy/open/" + this.vacancyUuid)
          .then(res => {
            this.open_vacancy = res.data.result.data.open_vacancies;
            this.loadVacancy = false;
          })
          .catch(error => {
            this.$notify({
              title: "Ошибка ",
              type: "error",
              offset: 130,
              dangerouslyUseHTMLString: true,
              message: "Вакансия не найдено"
            });
          });
      }
    },
    setLanguage(val) {
      localStorage.setItem("sys_language", val);
      this.$i18n.locale = val;
      console.log(this.$i18n.locale);
    }
  }
};
</script>
<style lang="scss">
.el-dropdown-link {
  img {
    width: 40px;
    height: 30px;
  }
}

.drop-item {
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  padding: 5px 10px;
  img {
    width: 20px;
    height: 20px;
  }
}

.hoverly:hover {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.vacancy_list {
  font-weight: bold;
}
.vacancy_list_item {
  padding: 5px 0;
  a {
    color: #353535;
    &:hover {
      color: #409eff;
    }
  }
}
.link-mobil {
  display: none;
}

.block_1,
.block_2 {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
  position: relative;
}
.logins {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bekmega {
  width: 200px;
}
.gohr {
  width: 200px;
}
.bg-cwe {
  background-color: #dae6e9;
  width: 100%;
  min-height: 100vh;
  padding: 20px;
}
.container-my {
  width: 1300px;
  margin: auto;
}
@media (max-width: 1200px) {
  .container-my {
    width: 100%;
    margin: auto;
  }
}

@media (max-width: 500px) {
  .container-my {
    width: 100%;
    margin: auto;
  }
  /* .logins.text-center {
        flex-direction: column;
    }
    .logins.text-center {
        flex-direction: column;
    } */
  .bekmega {
    width: 100px;
  }
  .link-mobil {
    width: 100%;
    background: #00c200;
    display: inline-block;
    color: #fff;
    top: -18px;
    position: absolute;
    right: 0px;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
  }
}
@media (max-width: 320px) {
  .container-my {
    width: 100%;
    margin: auto;
  }
}
</style>
